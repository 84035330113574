@font-face {
  font-family: 'iconfont'; /* Project id 4800721 */
  /* Color fonts */
  src:
    url('iconfont.woff2?t=1735802534903') format('woff2'),
    url('iconfont.woff?t=1735802534903') format('woff'),
    url('iconfont.ttf?t=1735802534903') format('truetype');
}

.iconfont {
  font-family: 'iconfont' !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-zhongqi:before {
  content: '\e61c';
}

.icon-shanchu:before {
  content: '\e66d';
}
