.App {
  text-align: center;
  overflow: scroll; /* 保留滚动功能 */
  -ms-overflow-style: none;  /* IE 10+ */
  scrollbar-width: none; /* Firefox */
}

.App::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
