.chatRight {
  @apply w-72 py-4 px-8 bg-neutral-900 flex flex-col space-y-4 relative;
}

.chatRight .userTags {
  @apply flex flex-wrap gap-4 text-white;
}
.chatRight .userTags span {
  @apply text-white border border-white border-opacity-10 rounded-md px-3 py-1;
}
.userItem {
  @apply w-full mt-2 flex bg-[#303030] border rounded-[10px] items-start p-2 cursor-pointer;
}
.operation {
  @apply invisible flex items-center;
}
.userItem:hover .operation {
  @apply visible;
}
.unreadCount {
  @apply bg-red-500 rounded-full absolute top-0 left-8 z-10 flex items-center justify-center text-xs;
  width: 14px;
  height: 14px;
}
