.list {
  @apply mx-auto mt-6 md:mb-0 mb-10 grid max-w-2xl grid-cols-2 gap-2 lg:gap-6 lg:mx-0 lg:max-w-none md:grid-cols-3 xl:grid-cols-4;
}
.listItem {
  @apply relative h-[400px] max-h-[400px] w-full max-w-[300px] cursor-pointer;
}
.listItem::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 45%;
  background: linear-gradient(#0000, #0e0e0eb3);
  pointer-events: none;
  z-index: 1;
}
